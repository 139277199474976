import { React, useEffect, useState } from "react";
import "./edituser.css";
import circle from "../../../assets/dfuser.jpg";
import web from "../../../assets/web.png";
import fb from "../../../assets/fb.png";
import insta from "../../../assets/insta.png";
import twitter from "../../../assets/twitter.png";
import linkedin from "../../../assets/linkdin.png";
import phone from "../../../assets/phone.png";
import mail from "../../../assets/mail.png";
import axios from "axios";
import { connect } from "react-redux";
import { months, newCountries } from "../StaticData/Data";
import { BeatLoader } from "react-spinners";
import { fetchUsersAndOtherDetails } from "../../../actions/userDetailsAction";
import "./../../../css/plugins/react_phone_number_input/style.css";
import PhoneInput, {
  isSupportedCountry,
  parsePhoneNumber,
} from "react-phone-number-input";
import APIBASEPATH from "../../../constants/apiBasePath";

function EditUser(props) {
  const { id, data, allYears, fetchData, close } = props;

  useEffect(() => {
    setIntialCountryTownData();
    setInitialHouseStreetData();
    setInitialMobileValue();
    setIntialCountryCode(data.mobileNo);
    const date = data.dateOfBirth.split("-");
    setDobYear(date[0]);
    setDobDay(date[2]);
    setDobMonth(months[date[1] - 1].text);

    console.log(id);

    if (data.profileImg != null) {
      setProfileImg({
        id: data.profileImg.id,
        imgPath: data.profileImg.imgPath,
        prevPathList: [],
      });
      setImg(data.profileImg.imgPath);
    }
  }, []);

  const [dobDay, setDobDay] = useState();
  const [dobMonth, setDobMonth] = useState();
  const [dobYear, setDobYear] = useState();

  const [selectedFile, setSelectedFile] = useState(null);
  const [img, setImg] = useState(circle);
  const [base64Data, setBase64Data] = useState("");

  const [profileImg, setProfileImg] = useState();

  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const [genralName, setGeneralName] = useState(data.nameUsedAtSTCG);
  const [webLink, setWebLink] = useState(data.webAddress);
  const [fbLink, setFbLink] = useState(data.fbUrl);
  const [instaLink, setInstaLink] = useState(data.IgUrl);
  const [twiiterLink, setTwitterLink] = useState(data.twitterUrl);
  const [linkedIn, setLinkedIn] = useState(data.linkedinUrl);
  const [hobbie01, setHobbie01] = useState(data.hobbies[0]);
  const [hobbie02, setHobbie02] = useState(data.hobbies[1]);
  const [hobbie03, setHobbie03] = useState(data.hobbies[2]);
  const [mobile, setMobile] = useState(data.mobileNo);
  const [home, setHome] = useState(data.landPhoneNo);
  const [email, setEmail] = useState(data.email);
  const [houseNum, setHouseNum] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState(data.postalCode);
  const [town, setTown] = useState(data.town);
  const [country, setCountry] = useState(data.country);
  const [occupation, setOccupation] = useState(data.occupationDesignation);
  console.log(data.professionKeywords);
  const [profession, setProfession] = useState(
    data.professionKeywords.join(", ")
  );
  const [organization, setOrganization] = useState(data.organizationName);
  const [industry, setIndustry] = useState(data.natureOfOrganization);
  const [orgWeb, setOrgWeb] = useState(data.organizationWebAddress);
  const [selectedCountryCode, setSelectedCountryCode] = useState("");
  const [showCountryCodeSelector, setShowCountryCodeSelector] = useState(false);
  const [selectedMobileCountry, setSelectedMobileCountry] = useState(null);

  console.log(data.mobileNo);

  const normalizePhoneNumber1 = (input) => {
    if (input[0] !== "+") {
      input = "+" + input;
    }
    // console.log("mobile", data.mobileNo);
    const parsedPhone = parsePhoneNumber(input);
    console.log(input, "normalizePhoneNumber1 as:", parsedPhone);
    if (parsedPhone) {
      return parsedPhone.number;
    } else {
      return input;
    }
  };

  const normalizePhoneNumberCountry = (input) => {
    if (input[0] !== "+") {
      input = "+" + input;
    }
    const parsedPhone = parsePhoneNumber(input);
    console.log(input, "normalizePhoneNumberCountry as:", parsedPhone);
    if (parsedPhone) {
      return parsedPhone.country;
    } else {
      return null;
    }
  };

  const setInitialMobileValue = () => {
    try {
      const mobile = data.mobileNo;
      const country = normalizePhoneNumberCountry(mobile);
      setSelectedMobileCountry(country);
      const phone = normalizePhoneNumber1(mobile);
      setMobile(phone);
    } catch (error) {
      console.log(error);
    }
  };

  const setIntialCountryTownData = () => {
    const matchingCountry = newCountries.find(
      (item) => item?.name === country?.text
    );
    setCountry(matchingCountry);
  };

  const setIntialCountryCode = (phoneNumber) => {
    try {
      const lastNineDigits = phoneNumber.substring(phoneNumber.length - 9);
      // console.log(lastNineDigits)
      let countryCode = phoneNumber.split(lastNineDigits)[0];
      // console.log(countryCode)
      setSelectedCountryCode(countryCode);
    } catch (error) {
      console.log("edit user setIntialCountryCode", error);
    }
  };

  const setInitialHouseStreetData = () => {
    let arr = data.address.split(",");
    let firstpart = arr[0];
    // console.log('houseNum', firstpart);
    setHouseNum(firstpart);
    let secondPart = data.address.split(firstpart)[1].replace(/^,/, "");
    if (secondPart === " ") {
      secondPart = "";
    }
    setStreet(secondPart);
    // console.log('street',secondPart)
  };

  const getCountryCodeList = () => {
    let list = [];

    for (let index = 0; index < newCountries.length; index++) {
      const c = newCountries[index];
      // if(c.phonecode === '856'){
      //   console.log(index);
      // }
      let code = c.phonecode;
      if (code.includes("+")) {
        code = code.split("+")[1];
      }
      list.push(code);
    }
    return list;
  };

  // const generateMobileNumberForSubmit = (phoneNumber) => {
  //   try {
  //     const lastNineDigits = phoneNumber.substring(phoneNumber.length - 9);
  //     phoneNumber = selectedCountryCode + lastNineDigits;
  //     // console.log(lastNineDigits)
  //     // let countryCode = phoneNumber.split(lastNineDigits)[0];
  //     // console.log(countryCode)
  //     return phoneNumber;
  //   } catch (error) {
  //     console.log(error);
  //     return phoneNumber;
  //   }
  // };

  const submit = async (e) => {
    if (country?.name === null) {
      return alert("Please select a country");
    }

    console.log("final mobile ", mobile);

    // if (!isValidPhoneNumber(mobile)) {
    //   return alert("Not a valid mobile number");
    // }

    // if (selectedCountryCode === "") {
    //   return alert("Please select country code");
    // }

    try {
      //generate Final Mobile number
      // const finalMobileNum = generateMobileNumberForSubmit(mobile);
      const finalMobileNum = mobile;

      const imgData = {
        base64Data: base64Data,
      };

      setLoading(true);
      if (selectedFile != null) {
        console.log("image selected");

        const response = await axios.post(
          `${APIBASEPATH}/api/v1/upload/profileImg`,
          imgData
        );

        console.log(response);

        const imgId = response.data.data.fileId;
        const url = response.data.data.url;

        const user = {
          nameUsedAtSTCG: genralName,
          webAddress: webLink,
          fbUrl: fbLink,
          IgUrl: instaLink,
          twitterUrl: twiiterLink,
          linkedinUrl: linkedIn,
          hobbies: [hobbie01, hobbie02, hobbie03],
          profileImg: {
            id: imgId ?? "",
            imgPath: url ?? "",
            prevPathList: [],
          },
          mobileNo: finalMobileNum,
          landPhoneNo: home,
          emailAddresses: [],
          address: `${houseNum} ${street}`,
          town: town || "",
          country: {
            id: country,
            text: country,
            value: country,
            countryCode: country,
          },
          postalCode: postalCode,
          occupationDesignation: occupation,
          professionKeywords: profession.split(","),
          organizationName: organization,
          natureOfOrganization: industry,
          organizationWebAddress: orgWeb,
        };

        const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        if (response.status == 200) {
          const res = await axios.post(
            `${APIBASEPATH}/api/v1/admin/update_user?id=${id}`,
            user,
            config
          );

          setLoading(false);
          setShowPopUp(true);
          console.log(res);
        }
      } else {
        const user = {
          nameUsedAtSTCG: genralName,
          webAddress: webLink,
          fbUrl: fbLink,
          IgUrl: instaLink,
          twitterUrl: twiiterLink,
          linkedinUrl: linkedIn,
          hobbies: [hobbie01, hobbie02, hobbie03],
          profileImg: profileImg,
          mobileNo: finalMobileNum,
          landPhoneNo: home,
          emailAddresses: [],
          address: `${houseNum} ${street}`,
          postalCode: postalCode,
          town: town || "",
          country: country
            ? {
                id: country.name,
                text: country.name,
                value: country.name,
                countryCode: country.phonecode,
              }
            : null,
          occupationDesignation: occupation,
          professionKeywords: profession.split(","),
          organizationName: organization,
          natureOfOrganization: industry,
          organizationWebAddress: orgWeb,
        };

        // console.log(user);
        // setLoading(false);

        // return;

        const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        console.log("image not selected");
        const res = await axios.post(
          `${APIBASEPATH}/api/v1/admin/update_user?id=${id}`,
          // `http://localhost:3001/api/v1/admin/update_user?id=${id}`,
          user,
          config
        );

        setLoading(false);
        setShowPopUp(true);
        console.log(res);
      }
    } catch (error) {
      setLoading(false);
      alert(error?.response?.data?.msg || error);
      console.log(error);
    }
  };

  const selectCountryCode = (str) => {
    setSelectedCountryCode(str);
    setShowCountryCodeSelector(false);
  };

  return (
    <div>
      <div className="container">
        {/* General User Details */}
        <div className="row filters-container mt-5 py-3">
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
            <div className="add-user-col d-flex align-items-center">
              <img src={img} className="add-admin-img me-3" />
              <div className="d-flex flex-column">
                <div className="add-admin-profile mb-2">Profile Avatar</div>
                <label
                  className="add-admin-img-btn px-3 p-2"
                  htmlFor="img-input"
                >
                  Add Image
                </label>
                <input
                  style={{ opacity: "0", width: "110px", height: "10px" }}
                  id="img-input"
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    setImg(URL.createObjectURL(e.target.files[0]));

                    const file = e.target.files[0];
                    const reader = new FileReader();

                    reader.onload = function (e) {
                      // The base64 data
                      const base64 = e.target.result;
                      setBase64Data(base64);
                      console.log(base64);
                    };
                    reader.readAsDataURL(file);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
            <div className="form-group">
              <div className="add-user-txt mb-2" style={{ opacity: "50%" }}>
                Full Name
              </div>
              <input
                className="add-user-input mb-4"
                placeholder=""
                disabled
                style={{ opacity: "50%" }}
                value={data.fullName}
              />
            </div>

            <div className="form-group">
              <div className="add-user-txt mb-2" style={{ opacity: "50%" }}>
                Nick Name
              </div>
              <input
                className="add-user-input mb-4"
                style={{ opacity: "50%" }}
                disabled
                value={data.nickName}
              />
            </div>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
            <div className="form-group">
              <div className="add-user-txt mb-2">
                Name generally used at STCG
              </div>
              <input
                className="add-user-input mb-4"
                value={genralName}
                onChange={(e) => {
                  setGeneralName(e.target.value);
                }}
              />
            </div>

            <div className="add-user-txt mb-2" style={{ opacity: "50%" }}>
              Date Of Birth
            </div>

            <div className="form-group">
              <div className="add-user-dob d-flex">
                <select
                  className="dob-date me-2 ps-2"
                  style={{ opacity: "50%" }}
                  disabled
                >
                  <option>{dobDay}</option>
                </select>
                <select
                  className="dob-year ps-2"
                  style={{ opacity: "50%" }}
                  disabled
                >
                  <option>{dobYear}</option>
                </select>
              </div>
            </div>

            <div className="form-group">
              <select
                className="dob-month me-2 mt-2 ps-2"
                style={{ opacity: "50%" }}
                disabled
              >
                <option>{dobMonth}</option>
              </select>
            </div>
          </div>
        </div>

        {/* Social Media Details */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-4">
            Social Media Account Details
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#fff",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={web} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Web Link"
                  value={webLink}
                  onChange={(e) => {
                    setWebLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#fff",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={fb} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Facebook URL"
                  value={fbLink}
                  onChange={(e) => {
                    setFbLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={insta} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Instagram URL"
                  value={instaLink}
                  onChange={(e) => {
                    setInstaLink(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={twitter} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Twitter URL"
                  value={twiiterLink}
                  onChange={(e) => {
                    setTwitterLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={linkedin} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Linkedin URL"
                  value={linkedIn}
                  onChange={(e) => {
                    setLinkedIn(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Hobbies */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-4">Hobbies</div>

          <div className="row mt-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 01"
                onChange={(e) => {
                  setHobbie01(e.target.value);
                }}
                value={hobbie01}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 02"
                onChange={(e) => {
                  setHobbie02(e.target.value);
                }}
                value={hobbie02}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 03"
                onChange={(e) => {
                  setHobbie03(e.target.value);
                }}
                value={hobbie03}
              />
            </div>
          </div>
        </div>

        {/* Contact Details */}
        <div className="row filters-container mt-4 py-4">
          <div
            className="add-user-section-heading mb-4"
            style={{ opacity: "100%" }}
          >
            Contact Details
          </div>

          <div className="row mt-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                {/* <div
                className="d-flex align-items-center img-container p-2 px-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowCountryCodeSelector(true);
                }}
              >
                <span className="mx-2 px-3 fw-bold" style={{ color: "white" }}>
                  +{selectedCountryCode}
                </span>
                <div className="" style={{ color: "white" }}></div>
              </div>
              <input
                className="link-input"
                placeholder="Mobile Phone Number"
                value={mobile}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              /> */}
                {!isSupportedCountry(selectedMobileCountry) ? (
                  <PhoneInput
                    placeholder="Mobile Phone Number"
                    withCountryCallingCode={false}
                    // addInternationalOption={false}
                    value={mobile}
                    onChange={setMobile}
                  />
                ) : (
                  <PhoneInput
                    placeholder="Mobile Phone Number"
                    withCountryCallingCode={false}
                    country={selectedMobileCountry}
                    // addInternationalOption={false}
                    value={mobile}
                    onChange={setMobile}
                  />
                )}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={phone} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Home Number"
                  value={home}
                  onChange={(e) => {
                    setHome(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#FFF",
                  borderRadius: "10px",
                  opacity: "50%",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={mail} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Email Address"
                  value={email}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        {/* Address */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-4">Address</div>

          <div className="row mt-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="House Number"
                value={houseNum}
                onChange={(e) => {
                  setHouseNum(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Street Name"
                value={street}
                onChange={(e) => {
                  setStreet(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Postal Code"
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="form-group">
                <select
                  className="dob-month me-2 mt-2 ps-2"
                  value={town}
                  onChange={(e) => {
                    setTown(e.target.value);
                  }}
                >
                  <option>Town</option>

                  {newCountries
                    .find((item) => item?.name === country?.name)
                    ?.cities?.map((city) => (
                      <option value={city?.name}>{city?.name}</option>
                    ))}
                </select>
              </div>
              <div className="form-group">
                <select
                  className="dob-month me-2 mt-2 ps-2"
                  value={country?.name}
                  onChange={(e) => {
                    const matchingCountry = newCountries.find(
                      (item) => item?.name === e.target.value
                    );
                    setCountry(matchingCountry);
                    //set town value to selected country's first city
                    // console.log(matchingCountry?.cities[0].name)
                    setTown(matchingCountry.cities[0]?.name ?? null);
                  }}
                >
                  <option>Country</option>

                  {newCountries.map((country) => (
                    <option value={country?.name}>{country?.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Ocupation Details */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-4">
            {" "}
            Occupation Details
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Occupation/Designation"
                onChange={(e) => {
                  setOccupation(e.target.value);
                }}
                value={occupation}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Professional keywords"
                onChange={(e) => {
                  setProfession(e.target.value);
                }}
                value={profession}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Name of organization"
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
                value={organization}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Industry / nature of organization"
                onChange={(e) => {
                  setIndustry(e.target.value);
                }}
                value={industry}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex mb-3"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={web} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Organization Web address"
                  onChange={(e) => {
                    setOrgWeb(e.target.value);
                  }}
                  value={orgWeb}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                style={{ opacity: "0" }}
                className="add-user-input mb-3 ps-3"
                placeholder="Name of organization"
                disabled
              />
            </div>
          </div>
        </div>

        {/* School Years */}
        <div className="row mt-5">
          <div className="scl-footer text-center py-3 px-2">
            YEARS AT S. THOMAS' COLLEGE GURUTALAWA
          </div>
          <div className="row scl-footer-details w-100 mx-0">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="row" style={{ opacity: "50%" }}>
                <div className="footer-section-half d-flex flex-column ">
                  <div className="footer-txt">From</div>
                  <input
                    className="footer-input"
                    value={data.collegeYearsFromTo.from}
                    disabled
                  />
                </div>

                <div className="footer-section-half d-flex flex-column">
                  <div className="footer-txt">To</div>
                  <input
                    className="footer-input"
                    value={data.collegeYearsFromTo.to}
                    disabled
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="footer-txt mt-5">GCE O/L Year</div>
                  <select className="ol-year me-2 mt-2 ps-2 mb-5" disabled>
                    <option>{data.gceOLYear.yearText}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="row" style={{ opacity: "50%" }}>
                <div className="footer-section-half d-flex flex-column ">
                  <div className="footer-txt">From Class</div>
                  <input
                    className="footer-input"
                    value={data.classesFromTo.from}
                    disabled
                  />
                </div>
                <div className="footer-section-half d-flex flex-column">
                  <div className="footer-txt">To Class</div>
                  <input
                    className="footer-input"
                    value={data.classesFromTo.to}
                    disabled
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="footer-txt mt-5">
                    If sat for GCE A/L from the college
                  </div>
                  <select className="ol-year me-2 mt-2 ps-2 mb-5" disabled>
                    <option>{data.gceALYear.yearText}</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 d-flex justify-content-end align-items-end mb-5">
              <button
                className="footer-btn-submit"
                onClick={() => {
                  submit();
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center pe-5 me-5 ps-5 pt-3  pb-5">
        <button
          className="footer-btn-close  ms-5 mx-2"
          onClick={() => {
            close();
          }}
        >
          Close
        </button>
      </div>

      {/* <div className="d-flex justify-content-center align-items-center scl-footer mt-4  py-4">
        IF you are a STCG OBA member
      </div>

      <div className="d-flex  scl-footer-details mb-5">
        <div className="footer-section  d-flex flex-column ps-5 pt-5 pb-5">
          <div className="footer-txt mb-2" style={{ opacity: "50%" }}>
            STCG OBA Membership Number
          </div>
          <input
            className="oba-membership ps-3"
            placeholder="19955115526625"
            style={{ opacity: "50%" }}
          />
        </div>
        <div className="footer-section  d-flex flex-column ps-5 pt-4 pb-5">
          <div className="footer-txt mb-2" style={{ opacity: "50%" }}>
            Do you confirm this user add a STCG OBA Member?
          </div>
          <div className="d-flex mt-1">
            <button className="footer-btn me-4" style={{ opacity: "50%" }}>
              Yes
            </button>
            <button className="footer-btn ms-2" style={{ opacity: "50%" }}>
              No
            </button>
          </div>
        </div>
        <div className="footer-section d-flex flex-column ps-5 pt-4 pb-5 ">
          <button
            className="footer-btn-submit ms-2 ms-5"
            onClick={() => {
              submit();
            }}
          >
            Submit
          </button>
        </div>
      </div> */}

      {loading == true && (
        <div className="pop-up" style={{}}>
          {/* <ClipLoader color="#52bfd9" size={50}/> */}
          <BeatLoader color="#0057a7" />
        </div>
      )}

      {showPopUp == true && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              User Updated Successfully
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowPopUp(false);
                fetchData();
                close();
              }}
            >
              close
            </div>
          </div>
        </div>
      )}
      {showCountryCodeSelector == true && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              Select Country Code
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3"
              style={{
                backgroundColor: "lightBlue",
                height: "400px",
                width: "500px",
                flexWrap: "wrap",
                overflow: "auto",
              }}
            >
              {/* <ul style={{margin: '0', listStyle: 'none', padding: '0',textAlign: 'center', whiteSpace: 'no-wrap'}}> */}
              {getCountryCodeList().map((code) => (
                <div
                  className="p-2"
                  style={{
                    cursor: "pointer",
                    margin: "3px",
                    backgroundColor: "white",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    selectCountryCode(code);
                  }}
                >
                  {code}
                </div>
              ))}
              {/* </ul> */}
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowCountryCodeSelector(false);
              }}
            >
              close
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  allYears: state.userReducer.years,
});
const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchUsersAndOtherDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditUser);
