import React, { useEffect, useState } from "react";
import "./viewuser.css";
import circle from "../../../assets/dfuser.jpg";
import web from "../../../assets/web.png";
import fb from "../../../assets/fb.png";
import insta from "../../../assets/insta.png";
import twitter from "../../../assets/twitter.png";
import linkedin from "../../../assets/linkdin.png";
import phone from "../../../assets/phone.png";
import mail2 from "../../../assets/mail.png";
import link from "../../../assets/link.png";
import contact from "../../../assets/contact.png";
import sendmail from "../../../assets/sendmail.png";
import { parsePhoneNumber } from "react-phone-number-input";

export default function ViewUser(props) {
  const { id, data, close } = props;

  const [img, setImg] = useState(circle);
  const [mail, setMail] = useState("");

  useEffect(() => {
    if (data.profileImg != null) {
      setImg(data.profileImg.imgPath);
    }

    setMail(`mailto:${data.email}`);
  }, []);

  const buildAddress = () => {
    let address = "";
    if (data.address && data.address !== "" && data.address !== " ") {
      address = data.address + ", ";
    }

    address = address + data.town;
    if (data.country?.text) {
      address = address + ", " + data.country?.text;
    }
    if (data.postalCode) {
      address = address + ", " + data.postalCode;
    }
    address = address.replace(/^, */, "");
    // console.log(data.address)
    // console.log(data.town)
    // console.log(data.country?.text)
    return address;
  };

  // const getCountryCodeFromMobile = (phoneNumber) => {
  //   console.log(phoneNumber.length);
  //   // const phoneNumber = '94713448562'; // Or '311123456789'
  //   const lastNineDigits = phoneNumber.substring(phoneNumber.length - 9);
  //   // console.log(lastNineDigits)
  //   let countryCode = phoneNumber.split(lastNineDigits)[0];
  //   // console.log(countryCode)
  //   return countryCode;
  // };

  const getCountryCodeFromMobile = (input) => {
    if (input[0] !== "+") {
      input = "+" + input;
    }
    const parsedPhone = parsePhoneNumber(input);
    // console.log(input, "normalizePhoneNumberCountry as:", parsedPhone);
    if (parsedPhone) {
      return parsedPhone.countryCallingCode;
    } else {
      return "";
    }
  };

  // const getLast9DigitsFromMobile = (phoneNumber) => {
  //   // const phoneNumber = '94713448562'; // Or '311123456789'
  //   const lastNineDigits = phoneNumber.substring(phoneNumber.length - 9);
  //   // console.log(lastNineDigits)
  //   // let countryCode = phoneNumber.split(lastNineDigits)[0];
  //   // console.log(countryCode)
  //   return lastNineDigits;
  // };
  const getPhoneNumberWithoutCountryCode = (input) => {
    if (input[0] !== "+") {
      input = "+" + input;
    }
    // console.log("mobile", data.mobileNo);
    const parsedPhone = parsePhoneNumber(input);
    // console.log(input, "normalizePhoneNumber1 as:", parsedPhone);
    if (parsedPhone) {
      return parsedPhone.nationalNumber;
    } else {
      return input;
    }
  };

  const buildOccupationDetails = () => {
    let str = "";
    try {
      str = data.occupationDesignation;
      if (data.organizationName && data.organizationName.trim() !== "") {
        str = str + " at " + data.organizationName;
      }
    } catch (error) {
      console.log(error);
    }
    return str;
  };

  const builNatureOfOrganization = () => {
    let str = null;
    if (data.natureOfOrganization && data.natureOfOrganization.trim() !== "") {
      str = data.natureOfOrganization;
    }

    return str;
  };

  return (
    <div>
      <div className="container">
        {/* General User Details */}
        <div className="row filters-container mt-4 py-4">
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2 d-flex justify-content-center align-items-center ">
            <img src={img} className="user-view-img me-3" />
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
            <div className="form-group">
              <div className="add-user-txt mb-2">Name</div>
              <input
                className="view-user-input mb-4"
                placeholder=""
                disabled
                value={data.fullName}
              />
            </div>
            <div className="form-group">
              <div className="add-user-txt mb-2">Nick Name</div>
              <input
                className="view-user-input mb-4"
                disabled
                value={data.nickName}
              />
            </div>
            <div className="form-group">
              <div className="add-user-txt mb-2">ID Or Passport Number</div>
              <input
                className="view-user-input mb-4"
                placeholder=""
                disabled
                value={data.idOrPassportNum}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
            <div className="form-group">
              <div className="add-user-txt mb-2">Name Of Used In STCG</div>
              <input
                className="view-user-input mb-4"
                placeholder=""
                disabled
                value={data.nameUsedAtSTCG}
              />
            </div>
            <div className="form-group">
              <div className="add-user-txt mb-2">Date of Birth</div>
              <input
                className="view-user-input mb-4"
                disabled
                value={data.dateOfBirth}
              />
            </div>
          </div>
        </div>

        {/* Social Media Details */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-4">
            Social Media Account Details
          </div>

          <div className="row px-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 ps-2">
              <div className="form-group w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-center img-container p-2">
                    <img src={web} className="web-img" />
                  </div>
                  <input
                    className="link-input-view"
                    placeholder="Web Link"
                    disabled
                    value={data.webAddress}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2"
                    onClick={() => {
                      if (data.webAddress?.length > 0) {
                        let url = data.webAddress;
                        if (!url.includes("https://")) {
                          url = "https://" + url;
                        }
                        window.open(url, "_blank");
                      }
                    }}
                  >
                    <img src={link} className="web-img" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 px-2">
              <div className="form-group w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-center img-container p-2">
                    <img src={fb} className="web-img" />
                  </div>
                  <input
                    className="link-input-view"
                    placeholder="Facebook URL"
                    disabled
                    value={data.fbUrl}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2"
                    onClick={() => {
                      if (data.fbUrl?.length > 0) {
                        let url = data.fbUrl;
                        if (!url.includes("https://")) {
                          url = "https://" + url;
                        }
                        window.open(url, "_blank");
                      }
                    }}
                  >
                    <img src={link} className="web-img" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 px-2">
              <div className="form-group w-100">
                <div
                  className="d-flex"
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-center img-container p-2">
                    <img src={insta} className="web-img" />
                  </div>
                  <input
                    className="link-input-view"
                    placeholder="Instagram URL"
                    disabled
                    value={data.IgUrl}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2"
                    onClick={() => {
                      if (data.IgUrl?.length > 0) {
                        let url = data.IgUrl;
                        if (!url.includes("https://")) {
                          url = "https://" + url;
                        }
                        window.open(url, "_blank");
                      }
                    }}
                  >
                    <img src={link} className="web-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4 px-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 ps-2">
              <div className="form-group w-100 ps-0">
                <div
                  className="d-flex w-100"
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-center img-container p-2">
                    <img src={twitter} className="web-img" />
                  </div>
                  <input
                    className="link-input-view"
                    placeholder="Twitter URL"
                    disabled
                    value={data.twitterUrl}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2"
                    onClick={() => {
                      if (data.twitterUrl?.length > 0) {
                        let url = data.twitterUrl;
                        if (!url.includes("https://")) {
                          url = "https://" + url;
                        }
                        window.open(url, "_blank");
                      }
                    }}
                  >
                    <img src={link} className="web-img" />
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 px-2">
              <div className="form-group w-100">
                <div
                  className="d-flex w-100 "
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-center img-container p-2">
                    <img src={linkedin} className="web-img" />
                  </div>
                  <input
                    className="link-input-view"
                    placeholder="Linkedin URL"
                    disabled
                    value={data.linkedinUrl}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2"
                    onClick={() => {
                      if (data.linkedinUrl?.length > 0) {
                        let url = data.linkedinUrl;
                        if (!url.includes("https://")) {
                          url = "https://" + url;
                        }
                        window.open(url, "_blank");
                      }
                    }}
                  >
                    <img src={link} className="web-img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 px-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                  opacity: "0",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-md-4">
                  <img src={insta} className="web-img" />
                </div>
                <input
                  className="link-input-view"
                  placeholder="Instagram URL"
                  value={data.IgUrl}
                />
                <div
                  className="d-flex justify-content-center img-container-link p-2 px-md-4"
                  onClick={() => {
                    if (data.IgUrl?.length > 0) {
                      let url = data.IgUrl;
                      if (!url.includes("https://")) {
                        url = "https://" + url;
                      }
                      window.open(url, "_blank");
                    }
                  }}
                >
                  <img src={link} className="web-img" />
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Hobbies */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-4">Hobbies</div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 01"
                disabled
                value={data.hobbies[0]}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 02"
                disabled
                value={data.hobbies[1]}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 03"
                disabled
                value={data.hobbies[2]}
              />
            </div>
          </div>
        </div>

        {/* Contact Details */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-4">Contact Details</div>

          <div className="row px-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 ps-2">
              <div className="form-group w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex align-items-center img-container p-2 px-md-2">
                    <span
                      className=" fw-bold"
                      style={{ color: "white", fontSize: "12px" }}
                    >
                      +{getCountryCodeFromMobile(data.mobileNo)}
                    </span>
                    <div className="" style={{ color: "white" }}></div>
                  </div>
                  <input
                    className="link-input-view ps-2"
                    placeholder="Mobile Phone Number"
                    disabled
                    value={getPhoneNumberWithoutCountryCode(data.mobileNo)}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2"
                    onClick={() => {
                      navigator.clipboard.writeText(data.mobileNo);
                      alert("copied to clipboard");
                    }}
                  >
                    <img src={contact} className="web-img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 px-2">
              <div className="form-group w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-center img-container p-2">
                    <img src={phone} className="web-img" />
                  </div>
                  <input
                    className="link-input-view"
                    placeholder="Home Number"
                    disabled
                    value={data.landPhoneNo}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2 "
                    onClick={() => {
                      navigator.clipboard.writeText(data.landPhoneNo);
                      alert("copied to clipboard");
                    }}
                  >
                    <img src={contact} className="web-img" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2 px-2">
              <div className="form-group w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    background: "rgba(247, 247, 247, 0.5)",
                    borderRadius: "10px",
                  }}
                >
                  <div className="d-flex justify-content-center img-container p-2">
                    <img src={mail2} className="web-img" />
                  </div>
                  <input
                    className="link-input-view"
                    placeholder="Email Address"
                    disabled
                    value={data.email}
                  />
                  <div
                    className="d-flex justify-content-center img-container-link p-2"
                    onClick={() => {
                      navigator.clipboard.writeText(data.email);
                      alert("copied to clipboard");
                    }}
                  >
                    <img src={contact} className="web-img" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Lives In */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-3">Lives In</div>
          <div className="row">
            <div className="col-12">
              <textarea
                disabled
                className="lives-in w-100 px-2"
                value={buildAddress()}
              />
            </div>
          </div>
        </div>

        {/* Occupation Details */}
        <div className="row filters-container mt-4 py-4">
          <div className="add-user-section-heading mb-3">
            Occupation Details
          </div>

          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                disabled
                className="lives-in ps-3 w-100"
                value={buildOccupationDetails()}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="w-100 d-flex link-container">
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={web} className="web-img" />
                </div>
                <input
                  disabled
                  className="lives-in-address w-100 ps-3"
                  value={data.organizationWebAddress}
                />
              </div>
            </div>
          </div>
          {builNatureOfOrganization() != null && (
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
                <input
                  disabled
                  className="lives-in ps-3 w-100"
                  value={builNatureOfOrganization()}
                />
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="w-100 d-flex link-container">
                <input
                  placeholder="professional keywords"
                  disabled
                  className="lives-in-address w-100 ps-3"
                  value={data.professionKeywords.join(", ")}
                />
              </div>
            </div>
          </div>
        </div>

        {/* School Years */}
        <div className="row mt-5">
          <div className="scl-footer text-center py-3 px-2">
            YEARS AT S. THOMAS' COLLEGE GURUTALAWA
          </div>
          <div className="row scl-footer-details w-100 mx-0 ps-0 ps-md-3">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="row">
                <div className="footer-section-half d-flex flex-column ">
                  <div className="footer-txt">From</div>
                  <input
                    className="footer-input ps-2"
                    disabled
                    value={data.collegeYearsFromTo.from}
                  />
                </div>
                <div className="footer-section-half d-flex flex-column">
                  <div className="footer-txt">To</div>
                  <input
                    className="footer-input ps-2"
                    disabled
                    value={data.collegeYearsFromTo.to}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="footer-txt mt-5">GCE O/L Year</div>
                  <input
                    className="ol-year-input me-2 mt-2 ps-2"
                    disabled
                    value={data.gceOLYear.yearText}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="footer-txt mt-5">
                    Member at STCG OBA Community
                  </div>
                  <div className="d-flex justify-content-between mt-2 member-id-con mb-5">
                    <div
                      className="img-container-link px-2 p-2"
                      style={{ color: "white", fontSize: "11px" }}
                    >
                      Member Id
                    </div>
                    <div className="d-flex align-items-center px-2">
                      {data.obaMembershipNo}
                    </div>
                    <div
                      className="d-flex justify-content-center img-container-link p-2"
                      onClick={() => {
                        navigator.clipboard.writeText(data.obaMembershipNo);
                        alert("copied to clipboard");
                      }}
                    >
                      <img src={contact} className="web-img" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="row">
                <div className="footer-section-half d-flex flex-column ">
                  <div className="footer-txt">From Class</div>
                  <input
                    className="footer-input ps-2"
                    disabled
                    value={data.classesFromTo.from}
                  />
                </div>
                <div className="footer-section-half d-flex flex-column">
                  <div className="footer-txt">To Class</div>
                  <input
                    className="footer-input ps-2"
                    disabled
                    value={data.classesFromTo.to}
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="footer-txt mt-5">
                    If sat for GCE A/L from the college
                  </div>
                  <input
                    className="ol-year-input me-2 mt-2 ps-2 mb-5"
                    disabled
                    value={data.gceALYear.yearText}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row pt-2">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <a
              className="email d-flex  justify-content-center align-items-center my-3"
              href={mail}
            >
              <img src={sendmail} className="mail-img" />
              <div className="px-3 send-mail">Send an E-mail</div>
            </a>
          </div>
        </div>
      </div>

      <div className="d-flex justify-content-center pe-5 me-5 ps-5  pb-5">
        <button
          className="footer-btn-close  ms-5 mx-2"
          onClick={() => {
            close();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
}
