import React, { useEffect, useState } from "react";
import "./user.css";
import user from "../../../assets/dfuser.jpg";
import bin from "../../../assets/bin.png";
import axios from "axios";
import { connect } from "react-redux";
import { fetchUsersAndOtherDetails } from "../../../actions/userDetailsAction";
import APIBASEPATH from "../../../constants/apiBasePath";

function User(props) {
  const { editUser, viewUser, name, data, id, fetchData, deleteUserPrompt } =
    props;

  const [msg, setMsg] = useState("");
  const [showPopUp, setShowPopUp] = useState(false);
  const [img, setImg] = useState(user);

  useEffect(() => {
    if (data.profileImg != null) {
      setImg(data.profileImg.imgPath);
    }
  }, []);

  // const removeUser = async () => {
  //   try {
  //     const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
  //     const config = {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     };

  //     const response = await axios.delete(
  //       `https://brotherhood-backend.vercel.app/api/v1/admin/delete_user?id=${id}`,
  //       config
  //     );
  //     console.log(response);
  //     if (response.status == 200) {
  //       setMsg("User Removed Successfully");
  //       setShowPopUp(true);
  //     }
  //   } catch (error) {
  //     console.log(error)
  //   }
  // };

  const aproveUser = async () => {
    try {
      const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const idObject = {
        id: id,
      };

      console.log(id);
      const response = await axios.post(
        `${APIBASEPATH}/api/v1/admin/approve_user?id=${id}`,
        idObject,
        config
      );
      console.log(response);
      if (response.status == 200) {
        setMsg("User Approved Successfully");
        setShowPopUp(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="w-100 relative d-flex flex-column flex-md-row align-items-center justify-content-between admin-item my-2 py-2 pe-md-5">
      <div className="d-flex flex-column flex-md-row align-items-center py-1 px-md-3">
        <img src={img} className="log-user-img" />
        <div className="d-flex flex-column ps-md-3 justify-content-center align-items-center align-items-md-start width-wrapper">
          <div className="admin-item-name">{name}</div>
          <div className="admin-item-member">
            Reg NO: {data.uniqueRegisterNo}
          </div>
        </div>
      </div>

      <div className="admin-btn-container d-flex justify-content-around align-items-center mt-3">
        <button
          className="user-aprove-btn px-3 py-2 me-1 me-md-3"
          style={{ display: data.userApproved ? "none" : "flex" }}
          onClick={() => {
            if (!data.userApproved) {
              aproveUser();
            }
          }}
        >
          Approve
        </button>
        <button
          className="admin-view-btn px-3 py-2 me-1 me-md-3"
          onClick={() => {
            viewUser(id, data);
          }}
        >
          View
        </button>
        <button
          className="user-edit-btn px-3 py-2 me-1 me-md-3"
          onClick={() => {
            editUser(id, data);
          }}
        >
          Edit
        </button>
        <button
          className="admin-remove-btn d-flex align-items-center px-3 py-2 me-1 me-md-3"
          onClick={() => {
            // removeUser();
            deleteUserPrompt(id);
          }}
        >
          <div>Remove</div>
          <img src={bin} className="bin-img ms-2 me-2" />
        </button>
      </div>

      {showPopUp && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              {msg}
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowPopUp(false);
                fetchData(1);
              }}
            >
              Close
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  fetchData: () => dispatch(fetchUsersAndOtherDetails()),
});

export default connect(null, null)(User);
