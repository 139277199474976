import React, { useEffect, useState } from "react";
import "./userpanel.css";
import search from "../../../assets/shape.png";
import User from "../user/User";
import { connect } from "react-redux";
import axios from "axios";
import { setLoading } from "../../../actions/setLaodingAction";
import APIBASEPATH from "../../../constants/apiBasePath";

function UserPanel(props) {
  const {
    userSearch,
    hideUserSearch,
    addUser,
    editUser,
    viewUser,
    data,
    isLoading,
    setIsLoading,
  } = props;

  const [users, setUsers] = useState([]);
  const [panelHeading, setPanelHeading] = useState("All Users");
  const [searchResultData, setSearchResultData] = useState(null);

  const [showDeleteUserSuccessPopup, setShowDeleteUserSuccessPopup] =
    useState(false);
  const [showDeleteUserPrompt, setShowDeleteUserPrompt] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  useEffect(() => {
    getSearchResults(1);
  }, []);

  // useEffect(() => {
  //   getSearchResults();
  //   console.log("rendered");
  // }, [searchResultData]);

  const dataSet = (searchResults) => {
    if (searchResults.length === 0) return setUsers([]);
    const x = [];
    searchResults.map((user) => {
      if (!user.userApproved) {
        x.push(user);
      }
    });

    searchResults.map((user) => {
      if (user.userApproved) {
        x.push(user);
      }

      setUsers(x);
    });
  };

  const pendingList = () => {
    const x = [];
    const pendingUsers = users.map((user) => {
      if (!user.userApproved) {
        x.push(user);
        return user;
      }
    });
    console.log(pendingUsers);
    console.log(x);
    console.log(users);
    setUsers(x);
  };

  const getSearchResults = async (page) => {
    try {
      setIsLoading(true);
      const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${APIBASEPATH}/api/v1/admin/users/getAll?page=${page}`,
        config
      );
      // const response = await axios.get(
      //   `http://localhost:3001/api/v1/admin/users/getAll?page=${page}`,
      //   config
      // );
      // console.log(response?.data?.data);
      if (response.status == 200) {
        const searchResults = response.data.data.results;
        setSearchResultData(response.data.data);
        dataSet(searchResults);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };

  const removeUser = async () => {
    const id = userIdToDelete;
    // console.log("user to delete id ", id);
    if (!userIdToDelete) {
      return alert("no user id to delete selected");
    }
    try {
      const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.delete(
        `${APIBASEPATH}/api/v1/admin/delete_user?id=${id}`,
        config
      );
      console.log(response);
      if (response.status == 200) {
        setShowDeleteUserSuccessPopup(true);
      }
    } catch (error) {
      console.log(error);
      alert(error);
    }
  };

  const showRemoveUserPopup = (userId) => {
    if (!userId) return;
    setUserIdToDelete(userId);
    setShowDeleteUserPrompt(true);
  };

  return (
    <div className="w-100 d-flex flex-column pt-4 px-2 px-md-5 px-lg-5 px-xl-5">
      <div className="row align-items-center">
        <div className="col-12 mb-2 col-sm-6">
          <div
            className="d-flex mx-auto ms-md-0 mt-2 align-items-center justify-content-start search-container py-2 mt-1 px-2"
            onClick={() => {
              userSearch();
            }}
          >
            <img src={search} className="search-img ms-md-2 me-3" />
            <input
              className="user-search-input"
              placeholder="Search Users"
              disabled
            />
          </div>
        </div>
        <div className="col-12 col-sm-6 ">
          <div className="d-flex justify-content-around justify-content-lg-end">
            <button
              className="d-flex align-items-center user-pendinglist-btn me-2"
              onClick={() => {
                console.log("clicked");
                setPanelHeading("Pending Users");
                pendingList();
              }}
            >
              <div className="mx-2">Pending List</div>
            </button>

            <button
              className="d-flex align-items-center add-user-btn px-3"
              onClick={() => {
                addUser();
              }}
            >
              <div className="fw-bold plus-txt">+</div>
              <div className="mx-2"> Add User</div>
            </button>
          </div>
        </div>
      </div>

      <div className="d-flex flex-column mt-4">
        <div className="px-4 p-3 admin-header">{panelHeading}</div>
        <div className="px-4 p-2 m-auto m-md-0">
          <button
            className="admin-view-btn px-2 p-1"
            onClick={() => {
              if (searchResultData?.prevPage === null) return;
              getSearchResults(searchResultData?.prevPage);
            }}
          >
            Prev
          </button>
          <div className="px-3 p-1 d-inline ">
            Page {searchResultData?.currentPage || 1}
          </div>
          <button
            className="admin-view-btn px-2 p-1"
            onClick={() => {
              getSearchResults(searchResultData?.nextPage || 2);
            }}
          >
            Next
          </button>
        </div>
        <div className="d-flex w-100 flex-column  bg-body">
          {isLoading == false &&
            users.length > 0 &&
            users.map((user) => (
              <User
                editUser={editUser}
                viewUser={viewUser}
                name={user.fullName}
                key={user._id}
                data={user}
                id={user._id}
                deleteUserPrompt={(id) => {
                  showRemoveUserPopup(id);
                }}
                fetchData={(val) => {
                  getSearchResults(val);
                }}
              />
            ))}
        </div>
        <div className="px-4 p-4 admin-footer"></div>
      </div>
      {showDeleteUserPrompt == true && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              Do you want to delete this user?
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3"
              style={{ backgroundColor: "lightblue", cursor: "pointer" }}
              onClick={() => {
                setShowDeleteUserPrompt(false);
                removeUser();
              }}
            >
              Yes
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowDeleteUserPrompt(false);
              }}
            >
              No
            </div>
          </div>
        </div>
      )}
      {showDeleteUserSuccessPopup == true && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              User Deleted Successfully
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowDeleteUserSuccessPopup(false);
                setUserIdToDelete(null);
                getSearchResults(1);
              }}
            >
              close
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setIsLoading: (val) => setLoading(dispatch, val),
});

const mapStateToProps = (state) => ({
  isLoading: state.userReducer.isLoading,
  data: state.userReducer.users,
});

export default connect(mapStateToProps, mapDispatchToProps)(UserPanel);
