import React, { useState } from "react";
import "./adminpanel.css";
import Admin from "../admin/Admin";
import AddAdmin from "../addAdmin/AddAdmin";
import { connect } from "react-redux";
import { fetchAdminDetails } from "../../../actions/adminDetailsAction";
import axios from "axios";
import APIBASEPATH from "../../../constants/apiBasePath";

function AdminPanel(props) {
  const { data, fetchAdmins } = props;
  const [showAddAdmin, setShowAddAdmin] = useState(false);

  const [showDeleteUserSuccessPopup, setShowDeleteUserSuccessPopup] =
    useState(false);
  const [showDeleteUserPrompt, setShowDeleteUserPrompt] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  const removeAdmin = async () => {
    const id = userIdToDelete;
    // console.log("user to delete id ", id);
    if (!userIdToDelete) {
      return alert("no admin id to delete selected");
    }
    try {
      const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.delete(
        `${APIBASEPATH}/api/v1/admin/delete?id=${id}`,
        config
      );
      console.log(response);
      if (response.status == 200) {
        setShowDeleteUserSuccessPopup(true);
        fetchAdmins();
      }
    } catch (error) {}
  };

  const showRemoveAdminPopup = (userId) => {
    if (!userId) return;
    setUserIdToDelete(userId);
    setShowDeleteUserPrompt(true);
  };

  return (
    <div
      className="w-100 d-flex flex-column pt-4 px-2 px-md-5 px-lg-5 px-xl-5"
      //  style={{ overflow:"hidden" }}
    >
      {showAddAdmin == true && (
        <AddAdmin
          setShowAddAdmin={(val) => {
            setShowAddAdmin(val);
          }}
        />
      )}
      {showAddAdmin == false && (
        <div className="d-flex justify-content-end me-md-4">
          <button
            className="d-flex align-items-center add-admin-btn px-2 px-md-3 py-md-1"
            onClick={() => {
              setShowAddAdmin(true);
            }}
          >
            <div className="fw-bold plus-txt">+</div>
            <div className="mx-2"> Add Admin</div>
          </button>
        </div>
      )}

      <div className="d-flex w-100 flex-column me-md-5 mt-4">
        <div className="px-4 p-3 admin-header">All Admins</div>
        <div className="d-flex w-100 flex-column bg-body">
          {data.map((admin) => (
            <Admin
              email={admin.email}
              id={admin._id}
              key={admin._id}
              deleteAdminPrompt={(id) => {
                showRemoveAdminPopup(id);
              }}
            />
          ))}
        </div>
        <div className="px-4 p-4 admin-footer"></div>
      </div>
      {showDeleteUserPrompt == true && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              Do you want to delete this user?
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3"
              style={{ backgroundColor: "lightblue", cursor: "pointer" }}
              onClick={() => {
                setShowDeleteUserPrompt(false);
                removeAdmin();
              }}
            >
              Yes
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowDeleteUserPrompt(false);
              }}
            >
              No
            </div>
          </div>
        </div>
      )}
      {showDeleteUserSuccessPopup == true && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              Admin Deleted Successfully
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowDeleteUserSuccessPopup(false);
              }}
            >
              close
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  data: state.adminReducer.admins,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdmins: () => dispatch(fetchAdminDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminPanel);
