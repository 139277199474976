import React, {useState} from "react";
import "./admin.css";
import user from "../../../assets/dfuser.jpg";
import bin from "../../../assets/bin.png";
import {connect} from "react-redux";
import {fetchAdminDetails} from "../../../actions/adminDetailsAction";

function Admin(props) {
    const {email, id, fetchAdmins, deleteAdminPrompt} = props;

    const [showPopUp, setShowPopUp] = useState(false)

    // const removeAdmin = async () => {
    //   try {
    //     const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
    //     const config = {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     };
    //     const response = await axios.delete(`https://brotherhood-backend.vercel.app/api/v1/admin/delete?id=${id}`,config);
    //      console.log(response)
    //      if(response.status==200){

    //        setShowPopUp(true)
    //       // setEmail("")
    //       // setPwd("")
    //       // setAdmin(admin)
    //       fetchAdmins()
    //     }

    //   } catch (error) {}
    // };

    return (

        <div className="d-flex flex-column flex-sm-row py-2 mt-2 px-2 align-items-center justify-content-between admin-item">
            <div className="d-flex w-100">
                <img src={user} className="col-4 log-user-img"/>
                <div className="col-8 d-flex flex-column ps-3 justify-content-center">
                    <div className="admin-item-name">{email}</div>
                    <div className="admin-item-member">Member</div>
                </div>
            </div>

            <div className="d-flex">
                <button className="admin-remove-btn d-flex align-items-center ps-4 pe-3 p-2 me-4"
                        onClick={() => {
                            // removeAdmin()
                            deleteAdminPrompt(id)
                        }}
                >
                    <div>Remove</div>
                    <img src={bin} className="bin-img ms-3 me-2"/>
                </button>
            </div>

            {
                showPopUp == true && (<div className="pop-up">
                    <div className="pop-up-container">
                        <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
                            Admin Removed Successfully
                        </div>
                        <div className="d-flex justify-content-center align-items-center p-3 pop-up-close"
                             onClick={() => {
                                 setShowPopUp(false)
                             }}
                        >
                            close
                        </div>

                    </div>
                </div>)
            }
        </div>


    );
}

const mapDispatchToProps = (dispatch) => ({
    fetchAdmins: () => dispatch(fetchAdminDetails()),
});

export default connect(null, mapDispatchToProps)(Admin);
