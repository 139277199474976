import "./privacy_policy.css";

function PrivacyPolicy() {
  return (
    <>
      <div className="privacy_policy__container">
        <div className="privacy_policy__container__heading">Privacy Policy</div>
        <div className="privacy_policy__container__subheading">
          Introduction
        </div>
        <div className="privacy_policy__container__text">
          Welcome to the privacy policy page of the STGC Brotherhood community
          app! We are thrilled to provide you with a platform that fosters
          connection and collaboration among our esteemed colleagues from around
          the world. Our app, designed exclusively for our school community,
          aims to bring us together in a digital space where we can engage,
          share ideas, and support one another. As we value your privacy and
          want to ensure that your personal information remains secure, this
          privacy policy outlines the measures we take to protect your data and
          explains how we collect, use, and disclose information within the STGC
          Brotherhood app. We encourage you to read this policy to understand
          how we handle your information and to make informed decisions about
          your participation in our community.
        </div>

        <div className="privacy_policy__container__subheading">
          Information we collect and receive
        </div>
        <div className="privacy_policy__container__subheading2">
          Personal Information Provided by You.
        </div>
        <div className="privacy_policy__container__text">
          The personal information that we collect depends on the context of
          your interactions with us and the Services, the choices you make, and
          the products and features you use. The personal information we collect
          may include the following:
        </div>
        <ul className="privacy_policy__container__ul">
          <li>Names</li>
          <li>Phone Numbers</li>
          <li>Email Addresses</li>
          <li>Mailing Addresses</li>
          <li>Job Titles</li>
          <li>Contact Preferences</li>
          <li>Social Account Details</li>
          <li>Billing Addresses</li>
        </ul>
        <div className="privacy_policy__container__text">
          Sensitive Information.
        </div>
        <div className="privacy_policy__container__text">
          We do not process sensitive information. All personal information that
          you provide to us must be true, complete, and accurate,and you must
          notify us of any changes to such personal information.
        </div>

        <div className="privacy_policy__container__subheading">
          How Do We Process Your Information?
        </div>
        <div className="privacy_policy__container__text">
          In Short: We process your information to provide, improve, and
          administer our Services, communicate with you, for security and fraud
          prevention, and to comply with law. We may also process your
          information for other purposes with your consent. We process your
          personal information for a variety of reasons, depending on how you
          interact with our Services, including:
        </div>
        <ul className="privacy_policy__container__ul">
          <li>
            To facilitate account creation and authentication and otherwise
            manage user accounts. We may process your information so you can
            create and log in to your account, as well as keep your account in
            working order.
          </li>
          <li>
            To save or protect an individual's vital interest. We may process
            your information when necessary to save or protect an individual’s
            vital interest, such as to prevent harm.
          </li>
        </ul>

        <div className="privacy_policy__container__text">
          How Do We Protect Your Information?
        </div>
        <div className="privacy_policy__container__text">
          STGC Brotherhood places the utmost importance on keeping your
          information secure. We have implemented stringent security measures to
          safeguard and guarantee the privacy of your personal information. We
          utilize industry-standard encryption protocols to protect the
          transmission of sensitive data within our application. In addition,
          access to your personal information is restricted to only those
          authorized personnel who require it to carry out their
          responsibilities related to the maintenance and enhancement of our
          services. Our systems are routinely scanned for potential
          vulnerabilities, and any identified risks are immediately mitigated.
          We will never sell, rent, or disclose your personal information to
          third parties without your consent, unless required by law. We are
          committed to protecting the privacy and maintaining the trust of the
          members of our esteemed STGC Brotherhood community, and we will
          continue to update and enhance our security practices to meet evolving
          privacy standards.
        </div>

        <div className="privacy_policy__container__text">
          When And With Whom Do We Share Your Personal Information?
        </div>
        <div className="privacy_policy__container__text">
          When a user is registered. We only share information with other
          members who have been verified.
        </div>

        <div className="privacy_policy__container__text">
          How Long Do We Keep Your Information?
        </div>
        <div className="privacy_policy__container__text">
          We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy notice, No purpose
          in this notice will require us keeping your personal information for
          longer than the period of time in which users have an account with us.
          When we have no ongoing legitimate business need to process your
          personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your
          personal information has been stored in backup archives), then we will
          securely store your personal information and isolate it from any
          further processing until deletion is possible.
        </div>

        <div className="privacy_policy__container__text">
          How Do We Keep Your Information Safe?
        </div>
        <div className="privacy_policy__container__text">
          We have implemented appropriate and reasonable technical and
          organizational security measures designed to protect the security of
          any personal information we process. However, despite our safeguards
          and efforts to secure your information, no electronic transmission
          over the Internet or information storage technology can be guaranteed
          to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able
          to defeat our security and improperly collect, access, steal, or
          modify your information. Although we will do our best to protect your
          personal information, transmission of personal information to and from
          our Services is at your own risk. You should only access the Services
          within a secure environment.
        </div>

        <div className="privacy_policy__container__subheading">
          Account Information
        </div>
        <div className="privacy_policy__container__text">
          If you would at any time like to review or change the information in
          your account or terminate your account, you can:
        </div>
        <ul className="privacy_policy__container__ul">
          <li>Log in to your account settings and update your user account.</li>
          <li>Contact us using the contact information provided.</li>
        </ul>
        <div className="privacy_policy__container__text">
          Upon your request to terminate your account, we will deactivate or
          delete your account and information from our active databases.
          However, we may retain some information in our files to prevent fraud,
          troubleshoot problems, assist with any investigations, enforce our
          legal terms and/or comply with applicable legal requirements.
        </div>
        <div className="privacy_policy__container__text">
          If you have questions or comments about your privacy rights, you may
          email us at brotherhoodstc@gmail.com.
        </div>

        <div className="privacy_policy__container__subheading">
          Controls For Do-Not-Track Features
        </div>
        <div className="privacy_policy__container__text">
          Most web browsers and some mobile operating systems and mobile
          applications include a Do-Not-Track ("DNT") feature or setting you can
          activate to signal your privacy preference not to have data about your
          online browsing activities monitored and collected. At this stage no
          uniform technology standard for recognizing and implementing DNT
          signals has been finalized. As such, we do not currently respond to
          DNT browser signals or any other mechanism that automatically
          communicates your choice not to be tracked online. If a standard for
          online tracking is adopted that we must follow in the future, we will
          inform you about that practice in a revised version of this privacy
          notice.
        </div>

        <div className="privacy_policy__container__subheading">
          Do We Make Updates To This Notice?
        </div>
        <div className="privacy_policy__container__text">
          We may update this privacy notice from time to time. The updated
          version will be indicated by an updated "Revised" date and the updated
          version will be effective as soon as it is accessible. If we make
          material changes to this privacy notice, we may notify you either by
          prominently posting a notice of such changes or by directly sending
          you a notification. We encourage you to review this privacy notice
          frequently to be informed of how we are protecting your information.
        </div>

        <div className="privacy_policy__container__subheading">
          How Can You Contact Us About This Notice?
        </div>
        <div className="privacy_policy__container__text">
          If you have questions or comments about this notice, you may email us
          at brotherhoodstc@gmail.com or contact us by post at:
        </div>

        <div className="privacy_policy__container__contact_address">
          STCG Brotherhood No 72A,
        </div>
        <div className="privacy_policy__container__contact_address">
          Kandy Road Kandy,
        </div>
        <div className="privacy_policy__container__contact_address">
          Central 20000
        </div>
        <div className="privacy_policy__container__contact_address">
          Sri Lanka
        </div>
        <div className="privacy_policy__container__contact_address">
          +94 707 666 788
        </div>

        <div className="privacy_policy__container__subheading">Conflict</div>
        <div className="privacy_policy__container__text">
          Please kindly note that where this Privacy Policy has been translated
          to any other non-English language, in the event of any inconsistencies
          in the meaning of any provision of the English language text of this
          Privacy Policy, the English language text shall prevail.
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
