import React, { useEffect, useState } from "react";
import "./adduser.css";
import circle from "../../../assets/circle.png";
import web from "../../../assets/web.png";
import fb from "../../../assets/fb.png";
import insta from "../../../assets/insta.png";
import twitter from "../../../assets/twitter.png";
import linkedin from "../../../assets/linkdin.png";
import phone from "../../../assets/phone.png";
import mail from "../../../assets/mail.png";
import { connect } from "react-redux";
import axios from "axios";
import { fetchAdminDetails } from "../../../actions/adminDetailsAction";
import { days, months, newCountries } from "../StaticData/Data";
import { BeatLoader } from "react-spinners";
import { fetchUsersAndOtherDetails } from "../../../actions/userDetailsAction";
import "./../../../css/plugins/react_phone_number_input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import APIBASEPATH from "../../../constants/apiBasePath";

function AddUser(props) {
  const { allYears, fetchData, close } = props;

  // const months = [{
  //   id:1,
  //   text:"January"
  // }]

  const [selectedFile, setSelectedFile] = useState(null);
  const [img, setImg] = useState(circle);
  const [base64Data, setBase64Data] = useState("");
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);

  const [profileImg, setProfileImg] = useState(null);
  const [showTown, setShowTown] = useState(false);
  const [cities, setCities] = useState([]);

  const [fName, setFName] = useState("");
  const [nickName, setNickName] = useState("");
  const [nic, setNic] = useState("");
  const [genralName, setGeneralName] = useState("");
  const [birthYear, setBirthYear] = useState("");
  const [birthMonth, setBirthMonth] = useState("");
  const [birthDate, setBirthDate] = useState("");
  const [webLink, setWebLink] = useState("");
  const [fbLink, setFbLink] = useState("");
  const [instaLink, setInstaLink] = useState("");
  const [twiiterLink, setTwitterLink] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [hobbie01, setHobbie01] = useState("");
  const [hobbie02, setHobbie02] = useState("");
  const [hobbie03, setHobbie03] = useState("");
  const [mobile, setMobile] = useState("");
  const [home, setHome] = useState("");
  const [email, setEmail] = useState("");
  const [houseNum, setHouseNum] = useState("");
  const [street, setStreet] = useState("");
  const [town, setTown] = useState("");
  const [country, setCountry] = useState("");
  const [occupation, setOccupation] = useState("");
  const [profession, setProfession] = useState("");
  const [organization, setOrganization] = useState("");
  const [industry, setIndustry] = useState("");
  const [orgWeb, setOrgWeb] = useState("");
  const [olFrom, setOlFrom] = useState("");
  const [olTo, setOlTo] = useState("");
  const [classFrom, setClassFrom] = useState("");
  const [classTo, setClassTo] = useState("");
  const [olYear, setOlYear] = useState("");
  const [alYear, setAlYear] = useState("");
  const [obaNum, setObaNum] = useState("");
  const [conf, setConf] = useState(false);
  const [pwd, setPwd] = useState("");
  const [postalCode, setPostalCode] = useState("");

  // const [selectedCountryCode, setSelectedCountryCode] = useState("");
  // const [showCountryCodeSelector, setShowCountryCodeSelector] = useState(false);

  useEffect(() => {});

  const submit = async (e) => {
    try {
      if (country?.name === null) {
        return alert("Please select a country");
      }

      const validMobile = isValidPhoneNumber(mobile);
      if (validMobile === false) {
        return alert("not a valid mobile number");
      }

      // if (selectedCountryCode === "") {
      //   return alert("Please select country code");
      // }

      if (!postalCode && postalCode === "") {
        return alert("Please select postal code");
      }

      if (!profession) {
        setProfession("");
      }

      //generate Final Mobile number
      // const finalMobileNum = generateMobileNumberForSubmit(mobile);

      const finalMobileNum = mobile;

      const imgData = {
        base64Data: base64Data,
      };
      let bMonth = birthMonth.padStart(2, "0");
      let bDate = birthDate.padStart(2, "0");
      const birth = `${birthYear}-${bMonth}-${bDate}`;

      //country validate and object
      const matchingCountry = newCountries.find(
        (item) => item.name === country
      );

      if (
        matchingCountry === "" ||
        matchingCountry === undefined ||
        matchingCountry === null
      ) {
        return alert("Please select country");
      }

      let selectedCountry = {
        id: matchingCountry.name,
        text: matchingCountry.name,
        value: matchingCountry.name,
        countryCode: matchingCountry.phonecode,
      };

      if (selectedFile != null) {
        setLoading(true);
        const response = await axios.post(
          `${APIBASEPATH}/api/v1/upload/profileImg`,
          imgData
        );

        console.log(response);

        const id = response.data.data.fileId;
        const url = response.data.data.url;

        const user = {
          fullName: fName,
          email: email,
          username: fName,
          nameUsedAtSTCG: genralName,
          nickName: nickName,
          dateOfBirth: birth,
          idOrPassportNum: nic,
          webAddress: webLink,
          fbUrl: fbLink,
          IgUrl: instaLink,
          twitterUrl: twiiterLink,
          linkedinUrl: linkedIn,
          hobbies: [hobbie01, hobbie02, hobbie03],
          profileImg: {
            id: id,
            imgPath: url,
            prevPathList: [],
          },
          mobileNo: finalMobileNum,
          landPhoneNo: home,
          emailAddresses: [],
          address: `${houseNum}, ${street}`,
          town: town,
          country: selectedCountry,
          postalCode: postalCode,
          occupationDesignation: occupation,
          professionKeywords: [profession],
          organizationName: organization,
          natureOfOrganization: industry,
          organizationWebAddress: orgWeb,
          collegeYearsFromTo: { from: olFrom, to: olTo },
          classesFromTo: { from: classFrom, to: classTo },
          gceOLYear: { yearValue: olYear, yearText: olYear },
          gceALYear: { yearValue: alYear, yearText: alYear },
          obaMembershipNo: obaNum,
          obaRequested: conf,
          passwordString: pwd,
        };

        if (response.status == 200) {
          const res = await axios.post(
            `${APIBASEPATH}/api/v1/users/register`,
            user
          );
          setLoading(false);
          setShowPopUp(true);
          console.log(res);
        }
      } else {
        setLoading(true);
        const user = {
          fullName: fName,
          email: email,
          username: fName,
          nameUsedAtSTCG: genralName,
          nickName: nickName,
          dateOfBirth: birth,
          idOrPassportNum: nic,
          webAddress: webLink,
          fbUrl: fbLink,
          IgUrl: instaLink,
          twitterUrl: twiiterLink,
          linkedinUrl: linkedIn,
          hobbies: [hobbie01, hobbie02, hobbie03],
          profileImg: null,
          mobileNo: finalMobileNum,
          landPhoneNo: home,
          emailAddresses: [],
          address: `${houseNum}, ${street}`,
          town: town,
          country: selectedCountry,
          postalCode: postalCode,
          occupationDesignation: occupation,
          professionKeywords: [profession],
          organizationName: organization,
          natureOfOrganization: industry,
          organizationWebAddress: orgWeb,
          collegeYearsFromTo: { from: olFrom, to: olTo },
          classesFromTo: { from: classFrom, to: classTo },
          gceOLYear: { yearValue: olYear, yearText: olYear },
          gceALYear: { yearValue: alYear, yearText: alYear },
          obaMembershipNo: obaNum,
          obaRequested: conf,
          passwordString: pwd,
        };

        const res = await axios.post(
          `${APIBASEPATH}/api/v1/users/register`,
          // "http://localhost:3001/api/v1/users/register",
          user
        );
        setLoading(false);
        setShowPopUp(true);
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
      alert(
        error?.response?.data?.msg ??
          "some error. All neccessary fields are filled?"
      );
    }
  };

  // const selectCountryCode = (str) => {
  // setSelectedCountryCode(str);
  // setShowCountryCodeSelector(false);
  // };

  const getCountryCodeList = () => {
    let list = [];

    for (let index = 0; index < newCountries.length; index++) {
      const c = newCountries[index];
      // if(c.phonecode === '856'){
      //   console.log(index);
      // }
      let code = c.phonecode;
      if (code.includes("+")) {
        code = code.split("+")[1];
      }
      list.push(code);
    }
    return list;
  };

  // const generateMobileNumberForSubmit = (phoneNumber) => {
  //   try {
  //     const lastNineDigits = phoneNumber.substring(phoneNumber.length - 9);
  //     phoneNumber = selectedCountryCode + lastNineDigits;
  //     // console.log(lastNineDigits)
  //     // let countryCode = phoneNumber.split(lastNineDigits)[0];
  //     // console.log(countryCode)
  //     return phoneNumber;
  //   } catch (error) {
  //     console.log(error);
  //     return phoneNumber;
  //   }
  // };

  const setCitiesInCountry = (name) => {
    newCountries.forEach((element) => {
      if (element.name == name) {
        setCities(element.cities);
        setTown(element.cities[0].name);
      }
    });
  };

  return (
    <div className="w-100">
      <div className="container">
        <div className="row filters-container mt-5 py-4">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="add-user-col d-flex align-items-center mb-4">
              <img src={img} className="add-admin-img me-3" />
              <div className="d-flex flex-column">
                <div className="add-admin-profile mb-2">Profile Avatar</div>
                <label
                  className="add-admin-img-btn px-3 p-2"
                  htmlFor="img-input"
                >
                  Add Image
                </label>
                <input
                  style={{ opacity: "0", width: "110px", height: "10px" }}
                  id="img-input"
                  type="file"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    setImg(URL.createObjectURL(e.target.files[0]));

                    const file = e.target.files[0];
                    const reader = new FileReader();

                    reader.onload = function (e) {
                      // The base64 data
                      const base64 = e.target.result;
                      setBase64Data(base64);
                      console.log(base64);
                    };
                    reader.readAsDataURL(file);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="form-group">
              <div className="add-user-txt mb-2">Full Name</div>
              <input
                className="add-user-input mb-3"
                placeholder=""
                onChange={(e) => {
                  setFName(e.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <div className="add-user-txt mb-2">Nick Name</div>
              <input
                className="add-user-input mb-3"
                onChange={(e) => {
                  setNickName(e.target.value);
                }}
              />
            </div>
            <div className="form-group">
              <div className="add-user-txt mb-2">NIC Number</div>
              <input
                className="add-user-input"
                onChange={(e) => {
                  setNic(e.target.value);
                }}
              />
            </div>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <div className="form-group">
              <div className="add-user-txt mb-2">
                Name generally used at STCG
              </div>
              <input
                className="add-user-input mb-3"
                onChange={(e) => {
                  setGeneralName(e.target.value);
                }}
              />
            </div>

            <div className="form-group">
              <div className="add-user-txt mb-2">Date Of Birth</div>
              <div className="add-user-dob d-flex flex-wrap">
                <div className="row">
                  <div className="col">
                    <select
                      className="dob-month me-2 mb-2 ps-2"
                      onChange={(e) => {
                        setBirthDate(e.target.value);
                      }}
                    >
                      <option>Date</option>
                      {days.map((data) => (
                        <option value={data}>{data}</option>
                      ))}
                    </select>
                  </div>

                  <div className="col">
                    <select
                      className="dob-month me-2 mb-2 ps-2"
                      onChange={(e) => {
                        setBirthYear(e.target.value);
                      }}
                    >
                      <option>Year of Birth</option>
                      {allYears.map((data) => (
                        <option value={data.value}>{data.text}</option>
                      ))}
                    </select>
                  </div>
                </div>
                <select
                  className="dob-month me-2 mb-2 ps-2"
                  onChange={(e) => {
                    setBirthMonth(e.target.value);
                  }}
                >
                  <option>Month</option>
                  {months.map((data) => (
                    <option value={data.id}>{data.text}</option>
                  ))}
                </select>
              </div>
            </div>

            <div className="form-group">
              <input
                className="add-user-input mb-3"
                placeholder="Enter User Password"
                onChange={(e) => {
                  setPwd(e.target.value);
                }}
              />
            </div>
          </div>
          {/* Add more input elements with icons here */}
        </div>

        {/* Social Media Details */}
        <div className="row  filters-container mt-5 py-5">
          <div className="add-user-section-heading mb-4">
            Social Media Account Details
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={web} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Web Link"
                  value={webLink}
                  onChange={(e) => {
                    setWebLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={fb} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Facebook URL"
                  value={fbLink}
                  onChange={(e) => {
                    setFbLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={insta} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Instagram URL"
                  value={instaLink}
                  onChange={(e) => {
                    setInstaLink(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={twitter} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Twitter URL"
                  value={twiiterLink}
                  onChange={(e) => {
                    setTwitterLink(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={linkedin} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Linkedin URL"
                  value={linkedIn}
                  onChange={(e) => {
                    setLinkedIn(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                  opacity: "0",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={insta} className="web-img" />
                </div>
                <input className="link-input" placeholder="Instagram URL" />
              </div>
            </div>
          </div>
        </div>

        {/* Hobbies */}
        <div className="row filters-container mt-5 py-5">
          <div className="add-user-section-heading mb-4">Hobbies</div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 01"
                onChange={(e) => {
                  setHobbie01(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 02"
                onChange={(e) => {
                  setHobbie02(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Hobbie 03"
                onChange={(e) => {
                  setHobbie03(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {/* Contact Details */}
        <div className="row filters-container mt-5 py-5">
          <div className="add-user-section-heading mb-4">Contact Details</div>
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-4  mt-2">
              <div className="form-group w-100">
                <div
                  className="d-flex w-100"
                  style={{
                    background: "#F7F7F7",
                    color: "#fff",
                    borderRadius: "10px",
                  }}
                >
                  {/* <div
                className="d-flex align-items-center img-container p-2 px-2"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setShowCountryCodeSelector(true);
                }}
              >
                <span className="mx-2 px-3 fw-bold" style={{ color: "white" }}>
                  +{selectedCountryCode}
                </span>
                <div className="" style={{ color: "white" }}></div>
              </div>
              <input
                className="link-input"
                placeholder="Mobile Phone Number"
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
              /> */}
                  <PhoneInput
                    placeholder="Mobile Phone Number"
                    value={""}
                    onChange={setMobile}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={phone} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Home Number"
                  onChange={(e) => {
                    setHome(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={mail} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Email Address"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Address */}
        <div className="row filters-container mt-5 py-5">
          <div className="add-user-section-heading mb-4">Address</div>
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div>
                <select
                  className="dob-month me-2 mt-2 ps-2 mb-3 "
                  onChange={(e) => {
                    setShowTown(true);
                    setCitiesInCountry(e.target.value);
                    setCountry(e.target.value);
                  }}
                >
                  <option>Country</option>

                  {newCountries.map((country) => (
                    <option value={country.name}>{country.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div style={{ opacity: showTown ? 100 : 0 }}>
                <select
                  className="dob-month me-2  ps-2 mb-3 "
                  onChange={(e) => {
                    setShowTown(true);
                    setTown(e.target.value);
                  }}
                >
                  {/* <option>Country</option> */}

                  {cities.map((city) => (
                    <option value={city.name}>{city.name}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>

          <div className="row mt-2" style={{ opacity: showTown ? 100 : 0 }}>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="House Number"
                onChange={(e) => {
                  setHouseNum(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Street Name"
                onChange={(e) => {
                  setStreet(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Postal Code"
                value={postalCode}
                onChange={(e) => {
                  setPostalCode(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {/* Ocupation Details */}
        <div className="row filters-container mt-5 py-5">
          <div className="add-user-section-heading mb-4">
            Occupation Details
          </div>

          <div className="row mt-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Occupation/Designation"
                onChange={(e) => {
                  setOccupation(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Profession"
                onChange={(e) => {
                  setProfession(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Name of organization"
                onChange={(e) => {
                  setOrganization(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="row mt-2">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                className="add-user-input mb-3 ps-3"
                placeholder="Industry / nature of organization"
                onChange={(e) => {
                  setIndustry(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div
                className="d-flex mb-3"
                style={{
                  background: "#F7F7F7",
                  borderRadius: "10px",
                }}
              >
                <div className="d-flex justify-content-center img-container p-2 px-4">
                  <img src={web} className="web-img" />
                </div>
                <input
                  className="link-input"
                  placeholder="Organization Web address"
                  onChange={(e) => {
                    setOrgWeb(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <input
                style={{ opacity: "0" }}
                className="add-user-input mb-3 ps-3"
                placeholder="Name of organization"
                onChange={(e) => {
                  setIndustry(e.target.value);
                }}
              />
            </div>
          </div>
        </div>

        {/* School Years */}
        <div className="row mt-5">
          <div className="scl-footer text-center py-3 px-2">
            YEARS AT S. THOMAS' COLLEGE GURUTALAWA
          </div>
          <div className="row scl-footer-details w-100 mx-0">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="row">
                <div className="footer-section-half d-flex flex-column ">
                  <div className="footer-txt">From</div>
                  <select
                    className="footer-input ps-2"
                    onChange={(e) => {
                      setOlFrom(e.target.value);
                    }}
                  >
                    {allYears.map((data) => (
                      <option value={data.text}>{data.text}</option>
                    ))}
                  </select>
                </div>

                <div className="footer-section-half d-flex flex-column">
                  <div className="footer-txt">To</div>
                  <select
                    className="footer-input ps-2"
                    onChange={(e) => {
                      setOlTo(e.target.value);
                    }}
                  >
                    {allYears.map((data) => (
                      <option value={data.text}>{data.text}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="footer-txt mt-5">GCE O/L Year</div>
                  <select
                    className="ol-year mt-2 ps-2 mb-5"
                    onChange={(e) => {
                      setOlYear(e.target.value);
                    }}
                  >
                    {/* <option>1995</option> */}
                    {allYears.map((data) => (
                      <option value={data.text}>{data.text}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="row">
                <div className="footer-section-half d-flex flex-column ">
                  <div className="footer-txt">From Class</div>
                  <input
                    className="footer-input-cls ps-2"
                    onChange={(e) => {
                      setClassFrom(e.target.value);
                    }}
                    required
                  />
                </div>
                <div className="footer-section-half d-flex flex-column">
                  <div className="footer-txt">To Class</div>
                  <input
                    className="footer-input-cls ps-2"
                    onChange={(e) => {
                      setClassTo(e.target.value);
                    }}
                    required
                  />
                </div>
              </div>

              <div className="row mt-2">
                <div className="col">
                  <div className="footer-txt mt-5">
                    If sat for GCE A/L from the college
                  </div>
                  <select
                    className="ol-year me-2 mt-2 ps-2 mb-5"
                    onChange={(e) => {
                      setAlYear(e.target.value);
                    }}
                  >
                    {/* <option>1995</option> */}
                    {allYears.map((data) => (
                      <option value={data.text}>{data.text}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-5">
          <div className="scl-footer text-center py-3 px-2">
            IF you are a STCG OBA member
          </div>
          <div className="row scl-footer-details py-2 pb-4 mx-0">
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="footer-txt mb-2">STCG OBA Membership Number</div>
              <input
                className="oba-membership ps-3"
                placeholder="19955115526625"
                onChange={(e) => {
                  setObaNum(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <div className="footer-txt mb-2">
                Do you confirm this user add a STCG OBA Member?
              </div>
              <div className="d-flex mt-1">
                <button
                  className={
                    !conf ? "footer-btn me-4" : "footer-btn-active me-4"
                  }
                  onClick={() => {
                    setConf(true);
                  }}
                >
                  Yes
                </button>
                <button
                  className={!conf ? "footer-btn-active" : "footer-btn"}
                  onClick={(e) => {
                    setConf(false);
                  }}
                >
                  No
                </button>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 mt-2">
              <button
                className="footer-btn-submit ms-2 ms-5"
                onClick={(e) => {
                  submit(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>

      {loading == true && (
        <div className="pop-up" style={{}}>
          {/* <ClipLoader color="#52bfd9" size={50}/> */}
          <BeatLoader color="#0057a7" />
        </div>
      )}

      {showPopUp == true && (
        <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              User Added Successfully
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowPopUp(false);
                fetchData();
                close();
              }}
            >
              close
            </div>
          </div>
        </div>
      )}

      {/* {showCountryCodeSelector == true && ( */}
      {/* <div className="pop-up">
          <div className="pop-up-container">
            <div className="d-flex justify-content-center align-items-center p-3 pop-up-heading">
              Select Country Code
            </div>
            <div
              className="d-flex justify-content-center align-items-center p-3"
              style={{
                backgroundColor: "lightBlue",
                height: "400px",
                width: "500px",
                flexWrap: "wrap",
                overflow: "auto",
              }}
            > */}
      {/* <ul style={{margin: '0', listStyle: 'none', padding: '0',textAlign: 'center', whiteSpace: 'no-wrap'}}> */}
      {/* {getCountryCodeList().map((code) => (
                <div
                  className="p-2"
                  style={{
                    cursor: "pointer",
                    margin: "3px",
                    backgroundColor: "white",
                    width: "100px",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    selectCountryCode(code);
                  }}
                >
                  {code}
                </div>
              ))} */}
      {/* </ul> */}
      {/* </div> */}
      {/* <div
              className="d-flex justify-content-center align-items-center p-3 pop-up-close"
              onClick={() => {
                setShowCountryCodeSelector(false);
              }}
            >
              close
            </div>
          </div>
        </div> */}
      {/* )} */}
    </div>
  );
}

const mapStateToProps = (state) => ({
  allYears: state.userReducer.years,
});
const mapDispatchToProps = (dispatch) => ({
  fetchAdmins: () => dispatch(fetchAdminDetails()),
  fetchData: () => dispatch(fetchUsersAndOtherDetails()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);
