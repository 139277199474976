import "./contact_us.css";
import GoogleMapReact from "google-map-react";
import { useState } from "react";
import axios from "axios";
import APIBASEPATH from "./../../constants/apiBasePath";

function ContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const defaultProps = {
    center: {
      lat: 6.847549645477832,
      lng: 80.89044602664882,
    },
    zoom: 11,
  };

  const handleApiLoaded = (map, maps) => {
    // use map and maps objects
  };

  const clearInputs = () => {
    setName("");
    setEmail("");
    setMsg("");
  };

  const submitFunc = async () => {
    console.log(name, email, msg);
    try {
      const token = localStorage.getItem("token"); // assuming the JWT token is stored in local storage
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const contactUsDetails = {
        fromName: name,
        fromEmailAddress: email,
        msg: msg,
      };
      clearInputs();
      const response = await axios.post(
        `${APIBASEPATH}/api/v1/contact/add`,
        contactUsDetails,
        config
      );
      //console.log(response)
      if (response.status == 200) {
        alert("Message is sent to admins successfuly!");
      }
    } catch (error) {
      alert(error?.response?.data?.msg);
      console.error(error);
    }
  };

  return (
    <>
      <div className="contact_us__container">
        <div className="contact_us__container__map_container">
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          >
            <Marker
              key="1"
              text="St Thomas' College"
              lat="6.847549645477832"
              lng="80.89044602664882"
            />
          </GoogleMapReact>
        </div>
        <div className="contact_us__container__contact_details_container">
          <div className="contact_us__container__contact_details_container__heading">
            Contact Us
          </div>
          <div className="contact_us__container__contact_details_container__subheading">
            STCG BROTHERHOOD
          </div>
          <div className="contact_us__container__contact_details_container__text">
            No. 72A, Kandy Road,
          </div>
          <div className="contact_us__container__contact_details_container__text">
            Sri Lanka.
          </div>
          <div style={{ paddingTop: "20px" }}></div>
          <div className="contact_us__container__contact_details_container__subheading">
            Hotline: <span>+94 707 666 788</span>
          </div>
          <div className="contact_us__container__contact_details_container__subheading">
            Web: <span>www.prodigies84.com</span>
          </div>
          <div className="contact_us__container__contact_details_container__subheading">
            Email: <span>stcgbrotherhood@gmail.com</span>
          </div>
          <div style={{ paddingTop: "20px" }}></div>
          <div className="contact_us__container__contact_form_container">
            <input
              type="text"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              placeholder="enter your name"
              className="custom-input"
            />
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              placeholder="enter your email"
              className="custom-input"
            />

            <textarea
              value={msg}
              onChange={(e) => {
                setMsg(e.target.value);
              }}
              placeholder="enter your message"
              className="custom-textarea"
            ></textarea>

            <div
              className="contact_us__container__contact_form_container-btn"
              onClick={submitFunc}
            >
              Submit
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ContactUs;

const Marker = ({ text, onClick }) => (
  <div
    style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      width: "24px",
      height: "24px",
      backgroundColor: "#000",
      border: "2px solid #fff",
      borderRadius: "100%",
      transform: "translate(-50%, -50%)",
      cursor: "pointer",
    }}
  ></div>
);
